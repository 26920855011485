import { ILocale, IQuake } from "@types"
import { TooltipHTML } from "./_TooltipHTML"

export class QuakeMarkerTooltip {
  private dataEntity: IQuake | null = null
  private element: HTMLDivElement

  private tooltipHTML: TooltipHTML

  private isVisible = true

  constructor(l: ILocale) {
    this.tooltipHTML = new TooltipHTML(l)
    this.element = this.tooltipHTML.element
  }

  onTooltipOpen = (newData: IQuake): void => {
    this.isVisible && this.setVisible(false)

    this.dataEntity = newData
    this.setTooltipData()
    this.setVisible(true)
  }

  updateData = (newData: Partial<IQuake>): void => {
    this.dataEntity = { ...this.dataEntity, ...newData } as IQuake
    this.setTooltipData()
  }

  mount = (parent: Element, preventCb: (element: Element) => void): void => {
    parent.appendChild(this.element)
    preventCb(this.element)
  }

  calcPosition = (overlayProjection: google.maps.MapCanvasProjection): void => {
    if (!this.dataEntity) return

    const pixelPos = overlayProjection.fromLatLngToDivPixel(this.dataEntity.coord)!

    this.element.style.left = Math.round(pixelPos.x - parseInt(this.element.style.width, 10) * 0.05) + "px"
    this.element.style.top = Math.round(pixelPos.y - parseInt(this.element.style.height, 10) * 1.1) + "px"
  }

  setVisible = (isVisible: boolean): void => {
    this.element.style.display = isVisible ? "flex" : "none"
    this.isVisible = isVisible
  }

  private setTooltipData = (): void => {
    if (!this.dataEntity) return

    this.tooltipHTML?.setDataEntity(this.dataEntity)
  }
}
