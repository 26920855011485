import { ILocale, IQuake } from "@types"
import { QuakeMarkerTooltip } from "./_QuakeMarkerTooltip"

export class TooltipController {
  private tooltip: QuakeMarkerTooltip
  private _id: number | null = null

  constructor(private getPanes: () => google.maps.MapCanvasProjection, l: ILocale) {
    this.tooltip = new QuakeMarkerTooltip(l)
  }

  get id(): number | null {
    return this._id
  }

  mountTooltip = (parent: Element, preventCb: (element: Element) => void): void => {
    this.tooltip.mount(parent, preventCb)
  }

  openTooltip = (data: IQuake): void => {
    if (data.id === this._id) {
      this.closeTooltip()
      return
    }

    this.tooltip.onTooltipOpen(data)
    this.tooltip.calcPosition(this.getPanes())

    this._id = data.id
  }

  closeTooltip = (): void => {
    this.tooltip.setVisible(false)
    this._id = null
  }

  calcTooltipPosition = (overlayProjection: google.maps.MapCanvasProjection): void => {
    this.tooltip.calcPosition(overlayProjection)
  }

  updateTooltipData = (newData: Partial<IQuake>): void => {
    this.tooltip.updateData(newData)
  }
}
