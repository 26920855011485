import { regions as _regions, theme } from "@constants"
import { ILocale, IQuake } from "@types"
import { getMagnitudeEValue, magColors, createCustomElement } from "../../_utils"
import { IParsedRegion, formatTime, parseDDtoDMSString } from "@utils"

import { parseRegions } from "@utils"

const regions = parseRegions(_regions)

export class TooltipHTML {
  private dataEntity: IQuake | null = null
  element: HTMLDivElement
  dynamicElements: Record<string, HTMLElement> = {}

  constructor(private l: ILocale) {
    const container = this.createContainer()
    const header = this.createHeader()
    const divider = this.createDivider()
    const body = this.createBody()

    container.appendChild(header)
    container.appendChild(divider)
    container.appendChild(body)

    this.element = container
  }

  private createContainer(): HTMLDivElement {
    const container = createCustomElement("div", {
      display: "none",
      position: "absolute",
      flexDirection: "column",
      width: "270px",
      height: "150px",
      borderRadius: "4px",
      background: "rgba(2, 2, 2, 0.8)",
      backdropFilter: "blur(5px)",
      zIndex: "10",
      padding: "5px 10px",
    })

    return container as HTMLDivElement
  }

  private createHeader(): HTMLDivElement {
    const header = createCustomElement("div", {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
      width: "100%",
      height: "56px",
    })

    const circle = this.createInfoCircle()

    const titleContainer = createCustomElement("div", {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "4px",
      height: "100%",
    })

    const title = createCustomElement("p", {
      margin: "0",
      fontSize: "14px",
      fontWeight: "600",
    })
    this.dynamicElements.title = title

    const coord = createCustomElement("p", {
      margin: "0",
      fontSize: "10px",
      color: theme.palette.secondary.A400!,
    })
    this.dynamicElements.coordSubtitle = coord

    titleContainer.appendChild(title)
    titleContainer.appendChild(coord)

    header.appendChild(circle)
    header.appendChild(titleContainer)

    return header as HTMLDivElement
  }

  private createInfoCircle(): HTMLDivElement {
    const outerCircle = createCustomElement("div", {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "56px",
      height: "56px",
      borderRadius: "50%",
      border: "2px solid transparent",
    })

    this.dynamicElements.outerCircle = outerCircle

    const innerCircle = createCustomElement("div", {
      width: "32px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
    })

    this.dynamicElements.innerCircle = innerCircle

    const magTitle = createCustomElement("p", {
      margin: "0",
      fontSize: "14px",
      fontWeight: "600",
    })

    this.dynamicElements.magTitle = magTitle

    const depthTitle = createCustomElement("div", {
      position: "absolute",
      left: "calc(50% - 17px)",
      top: "calc(100% - 7px)",
      margin: "0",
      fontSize: "10px",
      fontWeight: "600",
      padding: "0px 5px",
      background: "rgba(11, 12, 13, 1)",
    })

    this.dynamicElements.depthTitle = depthTitle

    innerCircle.appendChild(magTitle)

    outerCircle.appendChild(innerCircle)
    outerCircle.appendChild(depthTitle)

    return outerCircle as HTMLDivElement
  }

  private createDivider(): HTMLHRElement {
    const divider = createCustomElement("hr", {
      width: "100%",
      borderRight: "none",
      borderBottom: "none",
      borderLeft: "none",
      borderColor: theme.palette.secondary.A400!,
    })

    return divider as HTMLHRElement
  }

  private createBody(): HTMLDivElement {
    const createRow = ({
      rowTitleText,
      elementNames: { left, right },
    }: {
      rowTitleText: string
      elementNames: {
        left?: string
        right?: string
      }
    }): HTMLDivElement => {
      const container = createCustomElement("div", {
        width: "100%",
        height: "14px",
        display: "flex",
        flexDirection: "row",
        gap: "12px",
      })

      const rowTitle = createCustomElement("p", {
        width: "75px",
        margin: "0",
        fontSize: "10px",
        color: theme.palette.secondary.A400!,
      })
      rowTitle.innerHTML = rowTitleText

      const rowBody = createCustomElement("div", {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      })

      const leftSideP = createCustomElement("p", {
        margin: "0",
        fontSize: "12px",
        fontWeight: "600",
      })
      if (left) this.dynamicElements[left] = leftSideP

      const rightSideP = createCustomElement("p", {
        margin: "0",
        fontSize: "10px",
        color: theme.palette.secondary.A400!,
      })
      if (right) this.dynamicElements[right] = rightSideP

      rowBody.appendChild(leftSideP)
      rowBody.appendChild(rightSideP)

      container.appendChild(rowTitle)
      container.appendChild(rowBody)

      return container as HTMLDivElement
    }

    const body = createCustomElement("div", {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    })

    const magRow = createRow({
      rowTitleText: this.l.mapFilters.magnitude,
      elementNames: {
        left: "magCell",
        right: "stationName",
      },
    })

    const depthRow = createRow({
      rowTitleText: this.l.mapFilters.depth,
      elementNames: {
        left: "depthCell",
      },
    })

    const timeRow = createRow({
      rowTitleText: this.l.mapFilters.time,
      elementNames: {
        left: "timeCell",
        right: "gmtCell",
      },
    })

    const divider1 = this.createDivider()
    const divider2 = this.createDivider()

    body.appendChild(magRow)
    body.appendChild(divider1)
    body.appendChild(depthRow)
    body.appendChild(divider2)
    body.appendChild(timeRow)

    return body as HTMLDivElement
  }

  setDataEntity = (newData: IQuake): void => {
    this.dataEntity = newData

    this.setTooltipData()
  }

  private setTooltipData(): void {
    if (!this.dataEntity) return

    const {
      language,
      units: { km },
    } = this.l

    const color = magColors[getMagnitudeEValue(this.dataEntity.magnitude)]
    const region = regions[this.dataEntity.station.region.toString()]
    const [lat, lng] = parseDDtoDMSString(this.dataEntity.coord, this.l.language)

    this.dynamicElements.title.innerHTML = region[language.toLowerCase() as keyof IParsedRegion]

    this.dynamicElements.coordSubtitle.innerHTML = `${lat}, ${lng}`

    this.dynamicElements.outerCircle.style.borderColor = color
    this.dynamicElements.innerCircle.style.background = color

    this.dynamicElements.magTitle.innerHTML = this.dataEntity.magnitude.toString()
    this.dynamicElements.depthTitle.innerHTML = `${this.dataEntity.depth} ${km}`

    this.dynamicElements.magCell.innerHTML = this.dataEntity.magnitude.toString()
    this.dynamicElements.stationName.innerHTML = this.dataEntity.station.name
    this.dynamicElements.depthCell.innerHTML = `${this.dataEntity.depth} ${km}`
    this.dynamicElements.timeCell.innerHTML = formatTime(this.dataEntity.time)
    this.dynamicElements.gmtCell.innerHTML = getGMT(this.dataEntity.time)
  }
}

function getGMT(time: string): string {
  return "GMT +" + new Date(time).getTimezoneOffset() / -60
}
